/// TYPES ///
import { ISelfOrderEvent } from "api/types/event";
/// REACT ///
import { Fragment } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { setSelfOrderEvent } from "api/actions/event";
import { updateSelfOrderEvent } from "api/actions/event";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import ProgressColumn from "./column";
/// STYLES ///
import { btn_box_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
import { title_style } from "styles/quote_preview/section";

interface Props {
  events: ISelfOrderEvent[]
  selected_event_id: number | string,
}

export default function SelfOrderEventProgress({ events, selected_event_id }: Props) {
  const dispatch = useAppDispatch();

  return (
    <Grid container item xs={12} alignItems="center" sx={{ paddingBottom: "50px" }}>
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style} >
          Order Progress
        </Typography>
      </Grid>
      <Grid item xs={6} sx={btn_box_style}>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px" }}>
          {
            events.map((event: ISelfOrderEvent) => {
              return (
                <Fragment>
                  <ProgressColumn
                    id={event?.id ?? 0}
                    event_type={event?.event_type ?? ""}
                    time={event?.time ?? ""}
                    notify_email={event?.notify_email ?? ""}
                    description={event?.description ?? ""}
                    color={event?.color ?? "blue"}
                    completed={event?.completed ?? false}
                    booking={event?.booking ?? false}
                    booked={event?.booked ?? false}
                    setEvent={() => dispatch(setSelfOrderEvent(event))}
                    completeEvent={completed => dispatch(updateSelfOrderEvent({completed}, event?.id ?? 0))}
                    selected_id={selected_event_id} />
                  </Fragment>
              )
            })
          }
        </Stack>
      </Grid>
    </Grid>
  )
}
