/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { price_list_url } from "components/navigation/endpoints";

export const list: IBreadcrumb = {
  id: 0,
  label: "Prices",
  url: `/${price_list_url}`
}

export const overview: IBreadcrumb = {
  id: 1,
  label: "Overview",
  url: `/${price_list_url}/id`
}
