/// TYPES ///
import { Order } from "api/types/order";
import { SelfOrder } from "api/types/order";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// STYLES///
import { header_style } from "styles/universal/section_header";
import { item_style } from "styles/quote_preview/section";
import { item_align } from "styles/quote_preview/section";
import { title_style } from "styles/quote_preview/section";

interface Props {
  order: Order | SelfOrder,
  title?: string,
}

export default function EventOrder({ order, title }: Props) {
  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style}>
          {title ?? "Work Order"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="body2" sx={item_style()}>
          <b>Location</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {order?.job_data?.geo_address ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="body2" sx={item_style()}>
          <b>Site Number</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {order?.job_data?.phone_number ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="body2" sx={item_style()}>
          <b>Contact Email</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {order?.job_data?.email ?? ""}
        </Typography>
      </Grid>
    </Grid>
  )
}
