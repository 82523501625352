/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { sortCustomers } from "api/actions/end_customer";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import SortButton from "components/universal/button/sort";
/// STYLES ///
import { row_header_style } from "styles/universal/row";
/// SORT VALUES ///
import { NAME } from "values/sort/customer";
import { ADDRESS } from "values/sort/customer";
import { EMAIL } from "values/sort/customer";
import { CITY } from "values/sort/customer";
import { PHONE } from "values/sort/customer";

export default function EndCustomerHeader() {
  const dispatch = useAppDispatch();
  const [sort_type, setSortType] = useState<number>(-1);
  const [sort_up, setSortUp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(sortCustomers(sort_type, sort_up));
  }, [dispatch, sort_type, sort_up]);

  const setSort = (type: number) => {
    if (sort_type === type) {
      setSortUp(prev => !prev);
    }
    else {
      setSortType(type);
      setSortUp(false);
    }
  }

  return (
    <Fragment>
      <Grid item xs={4} md={2} sx={row_header_style}>
        <SortButton text="Name" handleSort={() => setSort(NAME)} is_current={sort_type === NAME} is_up={sort_up} />
      </Grid>
      <Grid item xs={4} md={3} sx={row_header_style}>
        <SortButton text="Address" handleSort={() => setSort(ADDRESS)} is_current={sort_type === ADDRESS} is_up={sort_up} />
      </Grid>
      <Grid item xs={0} md={2} sx={[row_header_style, {display: {xs: "none", md: "block"}}]}>
        <SortButton text="City" handleSort={() => setSort(CITY)} is_current={sort_type === CITY} is_up={sort_up} />
      </Grid>
      <Grid item xs={0} md={2} sx={[row_header_style, {display: {xs: "none", md: "block"}}]}>
       <SortButton text="Phone" handleSort={() => setSort(PHONE)} is_current={sort_type === PHONE} is_up={sort_up} />
      </Grid>
      <Grid item xs={4} md={3} sx={row_header_style}>
        <SortButton text="Email" handleSort={() => setSort(EMAIL)} is_current={sort_type === EMAIL} is_up={sort_up} />
      </Grid>
    </Fragment>
  )
}
