/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setEventFilter } from "api/actions/event/local/set_filter";
import { setNavDate } from "api/actions/event/local/set_nav_date";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// LUXON ///
import { DateTime } from "luxon";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// MUI X ///
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
/// COMPONENTS ///
import CustomEventBarItem from "./button/custom";
import OrderEventBarItem from "./button/order";
import SelfOrderEventBarItem from "./button/self_order";
/// STYLE ///
import { side_cont_style } from "styles/calendar/side_bar";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { create_url } from "components/navigation/endpoints";

export default function CalendarSidebar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));
  const [upcoming_events, setUpcomingEvents] = useState<IOrderEvent[]>([]);
  const [upcoming_so_events, setUpcomingSOEvents] = useState<ISelfOrderEvent[]>([]);
  const [upcoming_custom_events, setUpcomingCustomEvents] = useState<ICustomEvent[]>([]);
  const { custom_events, order_events, self_order_events, filter, nav_date } = useAppSelector((state) => state.event);

  useEffect(() => {
    const events: IOrderEvent[] = [...order_events];
    setUpcomingEvents(events.reverse().slice(0, 3));
  }, [order_events, nav_date]);

  useEffect(() => {
    const so_events: ISelfOrderEvent[] = [...self_order_events];
    setUpcomingSOEvents(so_events.reverse().slice(0, 3));
  }, [self_order_events, nav_date]);

  useEffect(() => {
    const cus_events: ICustomEvent[] = [...custom_events];
    setUpcomingCustomEvents(cus_events.reverse().slice(0, 3));
  }, [custom_events, nav_date]);

  if(!is_md){
    return null;
  }

  return (
    <Box sx={side_cont_style}>
      <Button variant="outlined" size="small" fullWidth onClick={() => navigate(`/${event_url}/${create_url}`)}>
        Create Event
      </Button>
      <DateCalendar
        value={DateTime.fromISO(nav_date).toLocal()} onChange={v => dispatch(setNavDate(v.toISO()))}
        views={['year', 'month', 'day']}
        sx={{
          height: "355px",
          width: "300px",
          padding: 0, margin: 0
        }} />
      <Typography variant="body2" color="primary" sx={{ paddingLeft: "15px" }}>
        <b>Upcoming Events</b>
      </Typography>
      <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: "15px" }}>
        {
          upcoming_events.map((e, i) => <OrderEventBarItem key={i} event={e} />)
        }
        {
          upcoming_so_events.map((e, i) => <SelfOrderEventBarItem key={i} event={e} />)
        }
        {
          upcoming_custom_events.map((e, i) => <CustomEventBarItem key={i} event={e} />)
        }
      </div>
      <Typography variant="body2" color="primary" sx={{ paddingLeft: "15px" }}>
        <b>Event Categories</b>
      </Typography>
      <FormGroup sx={{ paddingLeft: "15px" }}>
        <FormControlLabel control={<Checkbox size="small" checked={!filter?.template} onChange={e => dispatch(setEventFilter({ ...filter, template: !e.target.checked }))} />} label={
          <Typography variant="body2">
            Template
          </Typography>
        } />
        <FormControlLabel control={
          <Checkbox
            size="small"
            checked={!filter?.order_material}
            onChange={e => dispatch(setEventFilter({ ...filter, order_material: !e.target.checked }))} />} label={
              <Typography variant="body2">
                Order Material
              </Typography>
            } />
        <FormControlLabel control={<Checkbox size="small" checked={!filter?.fabricate_order} onChange={e => dispatch(setEventFilter({ ...filter, fabricate_order: !e.target.checked }))} />} label={
          <Typography variant="body2">
            Fabricate
          </Typography>
        } />
        <FormControlLabel control={<Checkbox size="small" checked={!filter?.install} onChange={e => dispatch(setEventFilter({ ...filter, install: !e.target.checked }))} />} label={
          <Typography variant="body2">
            Install
          </Typography>
        } />
        <FormControlLabel control={<Checkbox size="small" checked={!filter?.service_call} onChange={e => dispatch(setEventFilter({ ...filter, service_call: !e.target.checked }))} />} label={
          <Typography variant="body2">
            Service Call
          </Typography>
        } />
      </FormGroup>
    </Box>
  )
}
