/// MUIX ///
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid";
/// ICONS ///
import DeleteIcon from "@mui/icons-material/Delete";

export default function GridDeleteButton({id, handleDeleteClick} ) {
  const apiRef = useGridApiContext()

  if(apiRef.current.state.rows?.dataRowIdToModelLookup[id].required){
    return (
      <GridActionsCellItem
        disabled
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => handleDeleteClick(id)}
        color="inherit"
      />
    )
  }

  return (
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label="Delete"
      onClick={() => handleDeleteClick(id)}
      color="inherit"
    />
  )
}

