/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// DATE ///
import { DateTime } from "luxon";

interface Props {
  title: string,
  time: string,
  color: string,
  url: string
}

export default function DayEvent({ title, time, color, url }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <motion.button
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.03 }}
      style={{
        backgroundColor: `${color ?? "blue"}`,
        width: is_md ? "300px" : "100%",
        display: "flex",
        flexDirection: is_md ? "column" : "row",
        justifyContent: is_md ? "start" : "space-between",
        borderRadius: "5px",
        paddingTop: is_md ? "10px" : "4px",
        paddingBottom: is_md ? "10px" : "4px",
        paddingLeft: is_md ? "15px" : "8px",
        paddingRight: is_md ? "15px" : "8px",
        marginRight: is_md ? "5px" : "0px",
        marginLeft: is_md ? "5px" : "0px",
        cursor: "pointer",
        border: "none"
      }}
      onClick={() => navigate(url)}>
        <Typography variant="body2" align="left" sx={{ color: "#fff" }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: is_md ? "25px" : "0px", color: "#fff" }}>
          {DateTime.fromISO(time).toLocal().toFormat("h:mm a")}
        </Typography>
    </motion.button>
  )
}

