/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { restore } from "api/actions/history";
import { updateShopCooktop } from "api/actions/shop_quote";
import { updateLocalShopCooktop } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
import { replaceRestorePoint } from "api/actions/history";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { IHistoryActionType } from "api/types/history";
import { CutoutError } from "api/types/sketch";
/// COMPONENTS ///
import SaveCooktop from "components/quote/drawer/cooktop";
/// FUNCTIONS ///
import { getShopCounterByUuid } from "functions/sketch/get/counter";

interface Props {
  selected_cooktop: IShopCooktop,
  setSelectedCooktop: (cooktop: IShopCooktop | null) => void,
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function UpdateShopCooktop({
  selected_cooktop,
  setSelectedCooktop,
  open,
  setOpen
}: Props) {
  const dispatch = useAppDispatch();
  const { counters } = useAppSelector((state) => state.shop_quote);
  const [cooktop, setCooktop] = useState<IShopCooktop>({});
  const [error, setError] = useState<CutoutError>({});

  useEffect(() => {
    if (open && selected_cooktop) {
      setCooktop(selected_cooktop);
      dispatch(replaceRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
          desc: "Cancel Update Cooktop",
          undo_data: { ...selected_cooktop }
        }
      ));
    }
  }, [open, dispatch]);

  const update = (cooktop: IShopCooktop) => {
    dispatch(updateLocalShopCooktop(cooktop));
    setCooktop(cooktop);
  }

  const cancel = () => {
    dispatch(restore());
    setOpen(false);
    setError({});
  }

  const save = () => {
    let error: CutoutError = {};
    if (!cooktop?.length) {
      error.length_error = "Length must be greater than 0";
      error.length_has_error = true;
    }

    if (!cooktop?.width) {
      error.width_error = "Width must be greater than 0";
      error.width_has_error = true;
    }

    if (error.width_error || error.length_error) {
      setError(error);
      return;
    }

    dispatch(updateShopCooktop(cooktop, cooktop.id));
    dispatch(markShopQuoteChanged());

    const new_counter: IShopCounter = getShopCounterByUuid(cooktop.counter_uuid, counters);

    if (new_counter) {
      dispatch(createRestorePoint(
        {
          undo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
          redo_action: IHistoryActionType.UPDATE_SHOP_COOKTOP,
          undo_data: { ...selected_cooktop },
          redo_data: cooktop,
          desc: "Change Sink"
        }
      ));
    }
    setSelectedCooktop(null);
    setOpen(false);
  }


  return (
    <SaveCooktop
      open={open}
      setOpen={setOpen}
      num_burners={cooktop?.num_burners ?? 4}
      setNumBurners={(num_burners => update({ ...cooktop, num_burners }))}
      length={cooktop?.length ?? 30}
      setLength={length => update({ ...cooktop, length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      width={cooktop?.width ?? 26}
      setWidth={width => update({ ...cooktop, width })}
      width_error={error?.width_error ?? ""}
      width_has_error={error?.width_has_error ?? false}
      save={save}
      cancel={cancel}
      title="Edit Cooktop Cutout" />
  );
}

