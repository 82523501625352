/// REDUX ///
import { useAppSelector } from "api/hooks/apiHook";
import { useAppDispatch } from "api/hooks/apiHook";
/// ROUTER ///
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
/// REACT ///
import { Fragment } from "react";
import { useRef } from "react";
import { useState } from "react";
/// ACTIONS ///
import { setNavDate } from "api/actions/event/local/set_nav_date";
/// TYPES ///
import { Location } from "react-router-dom";
/// MUI ///
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
/// DATETIME ///
import { DateTime } from "luxon";
/// EVENT VALUES ///
import { MONTH } from "values/event";
import { DAY } from "values/event";
import { WEEK } from "values/event";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { month_url } from "components/navigation/endpoints";
import { week_url } from "components/navigation/endpoints";
import { day_url } from "components/navigation/endpoints";

interface Props {
  changeDate: (month: number, day: number) => void,
  event_view_type: string
}

export default function CalendarBar({ changeDate, event_view_type }: Props) {
  const location: Location<any> = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));
  const [menu_open, setMenuOpen] = useState<boolean>(false);
  const [date_open, setDateOpen] = useState<boolean>(false);
  const { nav_date } = useAppSelector((state) => state.event);
  const menuRef = useRef<HTMLButtonElement>(null);
  const dateRef = useRef<HTMLButtonElement>(null);

  const handleDateDown = () => {
    if (event_view_type === MONTH) {
      changeDate(-1, 0);
    }
    else if (event_view_type === WEEK) {
      changeDate(0, -7);
    }
    else {
      changeDate(0, -1);
    }
  }

  const handleDateUp = () => {
    if (event_view_type === MONTH) {
      changeDate(1, 0);
    }
    else if (event_view_type === WEEK) {
      changeDate(0, 7);
    }
    else {
      changeDate(0, 1);
    }
  }

  const getEventViewDisplay = (): string => {
    if (event_view_type === MONTH) {
      return `${DateTime.fromISO(nav_date).monthLong} ${DateTime.fromISO(nav_date).year}`;
    }
    else if (event_view_type === DAY) {
      return `${DateTime.fromISO(nav_date).monthLong} ${DateTime.fromISO(nav_date).day}`;
    }
    else if (event_view_type === WEEK) {
      return `${DateTime.fromISO(nav_date).startOf("week").toFormat("EEE d")} - ${DateTime.fromISO(nav_date).endOf("week").toFormat("EEE d")}`;
    }
    return "";
  }

  const updateDate = (new_date: string) => {
    dispatch(setNavDate(new_date));
    setDateOpen(false);
  }

  return (
    <Toolbar disableGutters={true} variant="dense">
        {
          is_md ?
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" sx={{ fontSize: 15, paddingLeft: "10px", marginRight: "15px" }}>
              {getEventViewDisplay()}
            </Typography>
            <IconButton onClick={handleDateDown} size="small">
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
            </IconButton>
            <IconButton onClick={handleDateUp} size="small">
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </IconButton>
          </Stack> :
          <Fragment>
            <Stack direction="row">
              <Button onClick={() => setDateOpen(true)}>
              <Typography variant="h5" sx={{ fontSize: 15, paddingLeft: "10px", textTransform: "none" }}>
                {getEventViewDisplay()}
              </Typography>
              </Button>
            </Stack>
            <Popper
              sx={{zIndex: 8000}}
              open={date_open}
              anchorEl={dateRef.current}
              role={undefined}
              placement="bottom-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setDateOpen(false)}>
                      <DateCalendar
                        value={DateTime.fromISO(nav_date).toLocal()} onChange={v => updateDate(v.toISO())}
                        views={['year', 'month', 'day']}
                        sx={{
                          height: "355px",
                          width: "300px",
                          padding: 0, margin: 0
                        }} />
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Fragment>
        }
      <div style={{ flexGrow: 1 }}>
      </div>
      {
        is_md ?
        <div style={{ gap: "10px", display: "flex", flexDirection: "row", alignItems: "center", paddingRight: "10px" }}>
          <Button
            onClick={() => navigate(`/${event_url}/${month_url}`)}
            variant={location.pathname.includes(month_url) ? "contained" : "outlined"}
            size="small">
            Month
          </Button>
          <Button
            onClick={() => navigate(`/${event_url}/${week_url}`)}
            variant={location.pathname.includes(week_url) ? "contained" : "outlined"}
            size="small">
            Week
          </Button>
          <Button
            onClick={() => navigate(`/${event_url}/${day_url}`)}
            variant={location.pathname.includes(day_url) ? "contained" : "outlined"}
            size="small">
            Day
          </Button>
        </div> :
        <Fragment>
          <IconButton sx={{marginRight: "10px"}} onClick={() => navigate(`/${event_url}/${create_url}`)} color="primary">
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </IconButton>
          <IconButton sx={{marginRight: "10px"}} ref={menuRef} onClick={() => setMenuOpen(true)} color="primary">
            <FontAwesomeIcon icon={faBars} size="xs"/>
          </IconButton>
          <Popper
            sx={{zIndex: 8000}}
            open={menu_open}
            anchorEl={menuRef.current}
            role={undefined}
            placement="bottom-end"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <MenuList
                      autoFocusItem={menu_open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      <MenuItem onClick={() => navigate(`/${event_url}/${month_url}`)}>
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <Typography variant="body1" sx={{paddingLeft: "10px", paddingRight: "10px"}}>Month</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => navigate(`/${event_url}/${week_url}`)}>
                        <FontAwesomeIcon icon={faCalendarWeek} />
                        <Typography variant="body1" sx={{paddingLeft: "10px", paddingRight: "10px"}}>Week</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => navigate(`/${event_url}/${day_url}`)}>
                        <FontAwesomeIcon icon={faClock} />
                        <Typography variant="body1" sx={{paddingLeft: "10px", paddingRight: "10px"}}>Day</Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Fragment>
      }
    </Toolbar>
  )
}
