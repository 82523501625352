/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
/// ACTIONS ///
import { clearMarketOrders } from "api/actions/order/market_order";
import { clearSelfOrders } from "api/actions/self_order/self_order/local/clear";
import { setBreadcrumbs } from "api/actions/ui/set";
import { setNavDate } from "api/actions/event/local/set_nav_date";
/// COMPONENTS ///
import Calendar from "./calendar";
import CalendarBar from "../calendar_bar";
import CalendarSidebar from "../side_bar";
/// MUI ///
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// FM ///
import { motion } from "framer-motion";
/// DATE ///
import { DateTime } from "luxon";
/// VALUES ///
import { MONTH } from "values/event";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
import { mobile_calendar_content_style } from "styles/universal/page";

export default function EventsMonthPage() {
  const dispatch = useAppDispatch();
  const { nav_date } = useAppSelector((state) => state.event);
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    return () => {
      dispatch(clearSelfOrders());
      dispatch(clearMarketOrders());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setBreadcrumbs([]));
  }, [dispatch]);

  const changeDate = (month_change: number, day_change: number) => {
    dispatch(setNavDate(DateTime.fromISO(nav_date).plus({ months: month_change, days: day_change }).toISO()))
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={is_md ? calendar_content_style : mobile_calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <CalendarBar changeDate={changeDate} event_view_type={MONTH} />
        <Calendar />
      </div>
      <CalendarSidebar />
    </motion.div>
  )
}
