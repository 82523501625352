/// REACT ///
import { ReactElement } from "react";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { title_section_style } from "styles/universal/page";
import { title_style } from "styles/universal/page";

interface Props {
  title: string,
  endProps?: ReactElement,
  size_md?: number,
  size_lg?: number,
  left_padding?: string,
  right_padding?: string
}

export default function PageTitle({ title, endProps, size_lg = 12, left_padding = "0px", right_padding = "0px" }: Props) {
  return (
    <Grid item xs={12} lg={size_lg} sx={{ ...title_section_style, paddingLeft: left_padding, paddingRight: right_padding, alignItems: "center" }}>
      <Typography variant="h3" sx={title_style}>
        {title}
      </Typography>
      <Box>
        {
          endProps ?
            endProps :
            null
        }
      </Box>
    </Grid>
  )
}
