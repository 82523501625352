/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// ACTIONS ///
import { sortQuotes } from "api/actions/quotes/quote";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import SortButton from "components/universal/button/sort";
/// STYLES ///
import { row_header_style } from "styles/universal/row";
/// SORT VALUES ///
import { NAME } from "values/sort/quote";
import { ADDRESS } from "values/sort/quote";
import { EMAIL } from "values/sort/quote";
import { DATE } from "values/sort/quote";

export default function QuotesHeader() {
  const dispatch = useAppDispatch();
  const [sort_type, setSortType] = useState<number>(-1);
  const [sort_up, setSortUp] = useState<boolean>(false);
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));


  useEffect(() => {
    dispatch(sortQuotes(sort_type, sort_up));
  }, [dispatch, sort_type, sort_up]);

  const setSort = (type: number) => {
    if (sort_type === type) {
      setSortUp(prev => !prev);
    }
    else {
      setSortType(type);
      setSortUp(false);
    }
  }

  return (
    <Fragment>
      <Grid item xs={4} md={2.5} sx={row_header_style}>
        <SortButton text="Date" handleSort={() => setSort(DATE)} is_current={sort_type === DATE} is_up={sort_up} />
      </Grid>
      <Grid item xs={4} md={3} sx={row_header_style}>
        <SortButton text={is_md ? "Quote Name" : "Quote"} handleSort={() => setSort(NAME)} is_current={sort_type === NAME} is_up={sort_up} />
      </Grid>
      <Grid item xs={4} md={3.5} sx={row_header_style}>
        <SortButton text="Account" handleSort={() => setSort(EMAIL)} is_current={sort_type === EMAIL} is_up={sort_up} />
      </Grid>
      <Grid item xs={0} md={3} sx={row_header_style}>
        {
          is_md ?
          <SortButton text="Job Site" handleSort={() => setSort(ADDRESS)} is_current={sort_type === ADDRESS} is_up={sort_up} /> :
          null
        }
      </Grid>
    </Fragment>
  )
}
