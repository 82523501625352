/// REACT ///
import { Fragment } from "react";
import { ReactNode } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// TYPES ///
import { ICustomer } from "api/types/customer";
/// MUI ///
import Box from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { item_align } from "styles/quote_preview/section";
import { btn_box_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { assign_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

interface Props {
  customer: ICustomer,
  base_url: string,
  setOpenSend: (open: boolean) => void,
  setOpenSignature: (open: boolean) => void,
  children?: ReactNode
}

export default function CustomerInfo({ customer, base_url, children, setOpenSend, setOpenSignature }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container item xs={12} lg={6} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style} >
          Account
        </Typography>
      </Grid>
      <Grid item xs={6} sx={btn_box_style}>
        {
          is_md ?
          <Fragment>
            <BaseButton text="Send" icon={faPaperPlane} clickEvent={() => setOpenSend(true)} />
            <BaseButton text="Sign" icon={faSignature} clickEvent={() => setOpenSignature(true)} />
            <BaseButton text="Assign" icon={faPlus} clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${assign_url}`)} />
            <BaseButton text="Edit" hide={!customer?.id} icon={faEdit} clickEvent={() => navigate(`/${end_customer_url}/${detail_url}/${customer?.id}/${update_url}`)} />
          </Fragment> :
          <Fragment>
            <Tooltip title="Email Quote">
              <IconButton color="primary" onClick={() => setOpenSend(true)}>
                <FontAwesomeIcon icon={faPaperPlane} size="xs" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Request Signature">
              <IconButton color="primary" onClick={() => setOpenSignature(true)}>
                <FontAwesomeIcon icon={faSignature} size="xs" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Assign Account">
              <IconButton color="primary" onClick={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${assign_url}`)}>
                <FontAwesomeIcon icon={faPlus} size="xs" />
              </IconButton>
            </Tooltip>
            {
              customer?.id ?
                <Tooltip title="Edit Account">
                  <IconButton color="primary" onClick={() => navigate(`/${end_customer_url}/${detail_url}/${customer?.id}/${update_url}`)}>
                    <FontAwesomeIcon icon={faEdit} size="xs" />
                  </IconButton>
                </Tooltip> :
                null
            }
          </Fragment>
        }
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {
        customer?.is_business ?
          <Fragment>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Business Name</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={[item_style(), item_align]}>
                {customer?.business_name ?? ""}
              </Typography>
            </Grid>
          </Fragment> :
          null
      }
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Name</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {`${customer?.first_name ?? ""} ${customer?.last_name ?? ""}`}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Address</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {customer?.address ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>City</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {customer?.city ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Province</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {customer?.province ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Postal Code</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {customer?.postal_code ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Email</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {customer?.email ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Phone Number</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {customer?.phone_number ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Notes</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          {customer?.entry_notes ?? ""}
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}
