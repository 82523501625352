/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getWO } from "api/actions/wo/wo";
import { getOrderEvents } from "api/actions/event";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
/// COMPONENTS ///
import CalendarSidebar from "../side_bar";
import EventOrder from "./order_detail";
import OrderEventProgress from "./progress/order";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
import { mobile_calendar_content_style } from "styles/universal/page";

export default function OrderEvent() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));
  const { id } = useParams();
  const { order } = useAppSelector((state) => state.order);
  const { order_event, order_events, updating } = useAppSelector((state) => state.event);

  useEffect(() => {
    if (id) {
      dispatch(getWO(id));
      dispatch(getOrderEvents({ order_id: id }));
    }
  }, [dispatch, id]);


  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={is_md ? calendar_content_style : mobile_calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <LoadingPage loading={!order?.id}>
          <Grid container alignItems="flex-start" spacing={3} sx={{ paddingTop: "25px", paddingRight: is_md ? "75px" : "0px" }}>
            <PageTitle title={order?.name ?? "Order"} />
            <OrderEventProgress events={order_events} selected_event_id={order_event?.id} />
            <EventOrder order={order} />
          </Grid>
        </LoadingPage>
      </div>
      <CalendarSidebar />
      <Loading loading={updating} />
    </motion.div >
  )
}
