/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getSelfOrderEvents } from "api/actions/event";
import { getShopOrder } from "api/actions/order/shop_order";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import EventOrder from "./order_detail";
import SelfOrderEventProgress from "./progress/self_order";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// ICON ///
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
import { mobile_calendar_content_style } from "styles/universal/page";
/// URLS ///
import { order_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";

export default function SelfOrderEvent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));
  const { id } = useParams();
  const { self_order } = useAppSelector(state => state.self_order);
  const { self_order_event, self_order_events, updating } = useAppSelector(state => state.event);

  useEffect(() => {
    if (id) {
      dispatch(getShopOrder(id));
      dispatch(getSelfOrderEvents({ self_order_id: id }));
    }
  }, [dispatch, id]);

  return (
    <motion.div
    	initial={m_init_page}
	exit={m_exit_page}
	animate={m_anim_page}
	transition={page_trans}
	style={is_md ? calendar_content_style : mobile_calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", paddingTop: "15px" }}>
        <LoadingPage loading={!self_order?.id}>
          <Grid container alignItems="flex-start" spacing={3}>
            <PageTitle title={self_order?.name ?? "Event"} endProps={
              <BaseButton text="View Order" icon={faClipboardCheck} clickEvent={() => navigate(`/${shop_url}/${order_url}/${self_order?.id}`)} />
	    } />
            <EventOrder order={self_order} title="Order" />
            <SelfOrderEventProgress events={self_order_events} selected_event_id={self_order_event?.id} />
           </Grid>
        </LoadingPage>
      </div>
      <Loading loading={updating} />
    </motion.div>
  )
}
