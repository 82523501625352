import { Counter } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { adjustVectorToScale } from "../scale";
import { adjustMeasurementToScale } from "../scale";
import { getBottomLeftVertex } from "functions/sketch/math";
import { getTopLeftVertex } from "functions/sketch/math";
import { getTopRightVertex } from "functions/sketch/math";
import { getBottomRightVertex } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { pointInQuad } from "functions/sketch/math";
import { getCounterColor } from "functions/sketch/get/color";
import { highlightCanvas } from "functions/sketch/context";

export function drawCooktops(
  context: CanvasRenderingContext2D,
  counters: Counter[],
  mouse: Vector2,
  can_highlight: boolean,
  zoom: number,
  offset?: Vector2
): Cooktop | null {
  let has_selected: boolean = false;
  let selected_cooktop: Cooktop | null = null;
  for (let i = 0; i < counters.length; i++) {
    const counter: Counter = counters[i];
    for (let j = 0; j < counter.cooktops.length; j++) {
      const cooktop_path = new Path2D();
      const cooktop: Cooktop = counter.cooktops[j];

      const pre_start_point: Vector2 = adjustVectorToScale(
        getBottomLeftVertex(cooktop.location, cooktop.angle, cooktop.width * 4, cooktop.length * 4),
        zoom
      );
      const pre_top_left: Vector2 = adjustVectorToScale(
        getTopLeftVertex(cooktop.location, cooktop.angle, cooktop.width * 4, cooktop.length * 4),
        zoom
      );
      const pre_top_right: Vector2 = adjustVectorToScale(
        getTopRightVertex(cooktop.location, cooktop.angle, cooktop.width * 4, cooktop.length * 4),
        zoom
      );
      const pre_bottom_right: Vector2 = adjustVectorToScale(
        getBottomRightVertex(cooktop.location, cooktop.angle, cooktop.width * 4, cooktop.length * 4),
        zoom
      );

      const start_point: Vector2 = { X: pre_start_point.X + offset.X, Y: pre_start_point.Y + offset.Y };
      const top_left: Vector2 = { X: pre_top_left.X + offset.X, Y: pre_top_left.Y + offset.Y };
      const top_right: Vector2 = { X: pre_top_right.X + offset.X, Y: pre_top_right.Y + offset.Y };
      const bottom_right: Vector2 = { X: pre_bottom_right.X + offset.X, Y: pre_bottom_right.Y + offset.Y };

      cooktop_path.moveTo(start_point.X, start_point.Y);
      cooktop_path.lineTo(top_left.X, top_left.Y);
      cooktop_path.lineTo(top_right.X, top_right.Y);
      cooktop_path.lineTo(bottom_right.X, bottom_right.Y);
      cooktop_path.lineTo(start_point.X, start_point.Y);

      const burner_width: number = cooktop.width / 3 * 0.9;
      const pre_first_location: Vector2 = adjustVectorToScale(
        getDistantPoint(cooktop.location, cooktop.angle - 35, burner_width * 4 * 1.5),
        zoom
      );
      const first_location: Vector2 = { X: pre_first_location.X + offset.X, Y: pre_first_location.Y + offset.Y };

      const outer_burner_width: number = adjustMeasurementToScale(burner_width / 2 * 4, zoom);
      const inner_burner_width: number = adjustMeasurementToScale((burner_width / 2 * 4) * 0.6, zoom);
      const first_c_1_start: Vector2 = getDistantPoint(first_location, 90, outer_burner_width);
      const first_c_2_start: Vector2 = getDistantPoint(first_location, 90, inner_burner_width);
      cooktop_path.moveTo(first_c_1_start.X, first_c_1_start.Y);
      cooktop_path.arc(first_location.X, first_location.Y, outer_burner_width, 0, 2 * Math.PI);
      cooktop_path.moveTo(first_c_2_start.X, first_c_2_start.Y);
      cooktop_path.arc(first_location.X, first_location.Y, inner_burner_width, 0, 2 * Math.PI);

      const pre_second_location: Vector2 = adjustVectorToScale(
        getDistantPoint(cooktop.location, cooktop.angle + 35, burner_width * 4 * 1.5),
        zoom
      );
      const second_location: Vector2 = { X: pre_second_location.X + offset.X, Y: pre_second_location.Y + offset.Y };

      const second_c_1_start: Vector2 = getDistantPoint(second_location, 90, outer_burner_width);
      const second_c_2_start: Vector2 = getDistantPoint(second_location, 90, inner_burner_width);
      cooktop_path.moveTo(second_c_1_start.X, second_c_1_start.Y);
      cooktop_path.arc(second_location.X, second_location.Y, outer_burner_width, 0, 2 * Math.PI);
      cooktop_path.moveTo(second_c_2_start.X, second_c_2_start.Y);
      cooktop_path.arc(second_location.X, second_location.Y, inner_burner_width, 0, 2 * Math.PI);

      const pre_third_location: Vector2 = adjustVectorToScale(
        getDistantPoint(cooktop.location, cooktop.angle + 145, burner_width * 4 * 1.5),
        zoom
      );
      const third_location: Vector2 = { X: pre_third_location.X + offset.X, Y: pre_third_location.Y + offset.Y };

      const third_c_1_start: Vector2 = getDistantPoint(third_location, 90, outer_burner_width);
      const third_c_2_start: Vector2 = getDistantPoint(third_location, 90, inner_burner_width);
      cooktop_path.moveTo(third_c_1_start.X, third_c_1_start.Y);
      cooktop_path.arc(third_location.X, third_location.Y, outer_burner_width, 0, 2 * Math.PI);
      cooktop_path.moveTo(third_c_2_start.X, third_c_2_start.Y);
      cooktop_path.arc(third_location.X, third_location.Y, inner_burner_width, 0, 2 * Math.PI);

      const pre_fourth_location: Vector2 = adjustVectorToScale(
        getDistantPoint(cooktop.location, cooktop.angle - 145, burner_width * 4 * 1.5),
        zoom
      );
      const fourth_location: Vector2 = { X: pre_fourth_location.X + offset.X, Y: pre_fourth_location.Y + offset.Y };

      const fourth_c_1_start: Vector2 = getDistantPoint(fourth_location, 90, outer_burner_width);
      const fourth_c_2_start: Vector2 = getDistantPoint(fourth_location, 90, inner_burner_width);
      cooktop_path.moveTo(fourth_c_1_start.X, fourth_c_1_start.Y);
      cooktop_path.arc(fourth_location.X, fourth_location.Y, outer_burner_width, 0, 2 * Math.PI);
      cooktop_path.moveTo(fourth_c_2_start.X, fourth_c_2_start.Y);
      cooktop_path.arc(fourth_location.X, fourth_location.Y, inner_burner_width, 0, 2 * Math.PI);

      if (cooktop.num_burners > 4) {
        const pre_fifth_location: Vector2 = adjustVectorToScale(
          getDistantPoint(cooktop.location, cooktop.angle + 90, burner_width * 4 * 0.86155),
          zoom
        );
        const fifth_location: Vector2 = {X: pre_fifth_location.X + offset.X, Y: pre_fifth_location.Y + offset.Y};

        const fifth_c_1_start: Vector2 = getDistantPoint(fifth_location, 90, outer_burner_width);
        const fifth_c_2_start: Vector2 = getDistantPoint(fifth_location, 90, inner_burner_width);
        cooktop_path.moveTo(fifth_c_1_start.X, fifth_c_1_start.Y);
        cooktop_path.arc(fifth_location.X, fifth_location.Y, outer_burner_width, 0, 2 * Math.PI);
        cooktop_path.moveTo(fifth_c_2_start.X, fifth_c_2_start.Y);
        cooktop_path.arc(fifth_location.X, fifth_location.Y, inner_burner_width, 0, 2 * Math.PI);
      }

      if (cooktop.num_burners > 5) {
        const pre_sixth_location: Vector2 = adjustVectorToScale(
          getDistantPoint(cooktop.location, cooktop.angle - 90, burner_width * 4 * 0.86155),
          zoom
        );
        const sixth_location: Vector2 = {X: pre_sixth_location.X + offset.X, Y: pre_sixth_location.Y + offset.Y};

        const sixth_c_1_start: Vector2 = getDistantPoint(sixth_location, 90, outer_burner_width);
        const sixth_c_2_start: Vector2 = getDistantPoint(sixth_location, 90, inner_burner_width);
        cooktop_path.moveTo(sixth_c_1_start.X, sixth_c_1_start.Y);
        cooktop_path.arc(sixth_location.X, sixth_location.Y, outer_burner_width, 0, 2 * Math.PI);
        cooktop_path.moveTo(sixth_c_2_start.X, sixth_c_2_start.Y);
        cooktop_path.arc(sixth_location.X, sixth_location.Y, inner_burner_width, 0, 2 * Math.PI);
      }

      if (can_highlight && !has_selected && pointInQuad(start_point, top_left, top_right, bottom_right, mouse)) {
        has_selected = true;
        selected_cooktop = cooktop;
        highlightCanvas(context);
      }
      else {
        context.strokeStyle = getCounterColor(counter.drawing_color);
      }
      context.lineWidth = 1;
      context.stroke(cooktop_path);
    }
  }
  return selected_cooktop;
}
