/// TYPES ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { Vector2 } from "api/types/sketch";
/// COMPONENTS ///
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Popover from "@mui/material/Popover";
/// ICONS ///
import ContentCutIcon from '@mui/icons-material/ContentCut';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
/// STYLES ////
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  current_area: string,
  areas: Area[] | IShopArea[],
  moveCutShape: (id: number, uuid: string, color: number) => void,
  rotateCutShape: (rotation_delta: number) => void,
  defineSeams: () => void
}

export default function CutShapeMenu({
  open,
  menu_location,
  current_area,
  areas,
  moveCutShape,
  rotateCutShape,
  defineSeams
}: Props) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}>
      <List>
        {
          areas.map((area: Area | IShopArea) => {
            if (area.uuid === current_area) {
              return null;
            }
            else {
              return (
                <ListItemButton onClick={() => moveCutShape(area.id, area.uuid, area.number - 1)} sx={menuItemStyle} key={area.uuid}>
                  <ListItemIcon>
                    <MoveUpIcon />
                  </ListItemIcon>
                  Move to {area.name}
                </ListItemButton>
              )
            }
          })
        }
        <ListItemButton onClick={defineSeams} sx={menuItemStyle}>
          <ListItemIcon>
            <ContentCutIcon />
          </ListItemIcon>
          Define Seams
        </ListItemButton>
        <ListItemButton onClick={() => rotateCutShape(-22.5)} sx={menuItemStyle} id="menu_rotate_left">
          <ListItemIcon id="menu_rotate_left_icon">
            <RotateLeftIcon id="menu_rotate_left_svg_icon" />
          </ListItemIcon>
          Rotate Left
        </ListItemButton>
        <ListItemButton onClick={() => rotateCutShape(22.5)} sx={menuItemStyle} id="menu_rotate_right">
          <ListItemIcon id="menu_rotate_right_icon">
            <RotateRightIcon id="menu_rotate_right_svg_icon" />
          </ListItemIcon>
          Rotate Right
        </ListItemButton>
      </List>
    </Popover>
  )
}
