/// TYPES ///
import { Quote } from "api/types/quote";
import { IShopQuote } from "api/types/shop_quote/quote";
/// MUI ///
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
/// DATE FUNCTIONS ///
import { getDate } from "functions/date";
import { getDateOrTime } from "functions/date";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { item_align } from "styles/quote_preview/section";


interface Props {
  quote: Quote | IShopQuote
}

export default function QuoteDetails({ quote }: Props) {
  return (
    <Grid container item xs={12} lg={6}>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Quote #</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {quote?.estimate_number ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Date Created</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(), item_align]}>
          {getDate(quote?.date_created ?? "")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Last Update</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={[item_style(false), item_align]}>
          {getDateOrTime(quote?.last_updated ?? "")}
        </Typography>
      </Grid>
    </Grid>
  )
}
