/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useState } from "react";
/// ACTIONS ///
import { createAddon } from "api/actions/quotes/addon";
import { updateAddon } from "api/actions/quotes/addon";
/// TYPES ///
import { LineItemError } from "api/error_types/line_item_error";
import { IAddon } from "api/types/quote";
import { Quote } from "api/types/quote";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  quote: Quote,
  open: boolean,
  title: string,
  setOpen: (open: boolean) => void,
  item: IAddon,
  setItem: (item: IAddon) => void
}

export default function SaveAddon({ quote, open, title, setOpen, item, setItem }: Props) {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<LineItemError>({});

  const setTaxable = (taxable: string) => {
    if (taxable === "Yes") {
      setItem({ ...item, taxable: true });
    }
    else {
      setItem({ ...item, taxable: false });
    }
  }
  const save = () => {
    let e: LineItemError = {};

    if (!item.price) {
      e.price_error = "Price must be a valid number.";
      e.price_has_error = true;
    }

    if (!item.name || item.name === "") {
      e.name_error = "Must enter a name for this product.";
      e.name_has_error = true;
    }

    setErrors(e);

    if (e.price_has_error || e.name_has_error) {
      return;
    }

    if (item?.id) {
      dispatch(updateAddon({ ...item, quote: quote.id }));
    }
    else {
      dispatch(createAddon({ ...item, quote: quote.id }));
    }
    setItem({});
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Box display="flex" flexDirection="column" sx={{ gap: "10px", paddingTop: "10px" }}>
          <TextField
            label="Name"
            value={item?.name ?? ""}
            error={errors.name_has_error}
            helperText={errors.name_error}
            onChange={(e) => setItem({ ...item, name: e.target.value })} />
          <TextField
            type="number"
            label="Price($)"
            error={errors.price_has_error}
            helperText={errors.price_error}
            value={item?.price > 0 ? item.price : ""}
            onChange={(e) => setItem({ ...item, price: Number(e.target.value) })} />
          <TextField
            select
            label="Taxable"
            value={item?.taxable ? "No" : "Yes"}
            onChange={(e) => setTaxable(e.target.value)}>
            <MenuItem key="Yes" value="Yes">Yes</MenuItem>
            <MenuItem key="No" value="No">No</MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <BaseButton text="Cancel" icon={faTimes} clickEvent={() => setOpen(false)} />
        <BaseButton text="Add" icon={faPlus} clickEvent={save} />
      </DialogActions>
    </Dialog>
  )
}
