/// DATETIME ///
import { DateTime } from "luxon";
/// MUI ///
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dayOfMonthStyle } from "styles/calendar/day";

interface Props {
  index: number,
  day: number,
  nav_date: string,
}

export default function DayHeader({ index, day, nav_date }: Props) {
  const getDayOfWeek = () => {
    switch (index) {
      case 0:
        return "Sun";
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
    }
  }

  if (day < 1) {
    return (
      <Typography variant="body2" align="center" sx={dayOfMonthStyle(DateTime.fromISO(nav_date).set({ day: day }).hasSame(DateTime.now(), "day"))}>
        {getDayOfWeek()}
      </Typography>
    )
  }
  else if(day < 7 && index < 7){
    return (
      <Typography variant="body2" align="center" sx={dayOfMonthStyle(DateTime.fromISO(nav_date).set({ day: day }).hasSame(DateTime.now(), "day"))}>
        {getDayOfWeek()} <br /> {day}
      </Typography>
    )
  }
  else{
    return (
      <Typography variant="body2" align="center" sx={dayOfMonthStyle(DateTime.fromISO(nav_date).set({ day: day }).hasSame(DateTime.now(), "day"))}>
        {day}
      </Typography>
    )
  }
}
