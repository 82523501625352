/// REACT ///
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// MUI ///
import Grid from "@mui/material/Grid";
/// LUXON ///
import { DateTime } from "luxon";
/// COMPONENTS ///
import WeekEvent from "../event";
/// COLORS ///
import { light_grey_color } from "styles/style";
/// FUNCTIONS ///
import { filterEventType } from "components/events/functions/event";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";

const grid_body_style = {
  minHeight: "80vh",
  borderRight: `1px solid ${light_grey_color}`,
  borderBottom: `1px solid ${light_grey_color}`,
  paddingTop: "10px"
}

export default function WeekBody() {
  const { custom_events } = useAppSelector(state => state.event);
  const { filter } = useAppSelector(state => state.event);
  const { order_events } = useAppSelector(state => state.event);
  const { self_order_events } = useAppSelector(state => state.event);
  const [current_order_mon, setCurrentOrderMon] = useState<IOrderEvent[]>([]);
  const [current_order_tue, setCurrentOrderTue] = useState<IOrderEvent[]>([]);
  const [current_order_wed, setCurrentOrderWed] = useState<IOrderEvent[]>([]);
  const [current_order_thu, setCurrentOrderThu] = useState<IOrderEvent[]>([]);
  const [current_order_fri, setCurrentOrderFri] = useState<IOrderEvent[]>([]);
  const [current_order_sat, setCurrentOrderSat] = useState<IOrderEvent[]>([]);
  const [current_order_sun, setCurrentOrderSun] = useState<IOrderEvent[]>([]);
  const [current_so_mon, setCurrentSOMon] = useState<ISelfOrderEvent[]>([]);
  const [current_so_tue, setCurrentSOTue] = useState<ISelfOrderEvent[]>([]);
  const [current_so_wed, setCurrentSOWed] = useState<ISelfOrderEvent[]>([]);
  const [current_so_thu, setCurrentSOThu] = useState<ISelfOrderEvent[]>([]);
  const [current_so_fri, setCurrentSOFri] = useState<ISelfOrderEvent[]>([]);
  const [current_so_sat, setCurrentSOSat] = useState<ISelfOrderEvent[]>([]);
  const [current_so_sun, setCurrentSOSun] = useState<ISelfOrderEvent[]>([]);
  const [current_custom_mon, setCurrentCustomMon] = useState<ICustomEvent[]>([]);
  const [current_custom_tue, setCurrentCustomTue] = useState<ICustomEvent[]>([]);
  const [current_custom_wed, setCurrentCustomWed] = useState<ICustomEvent[]>([]);
  const [current_custom_thu, setCurrentCustomThu] = useState<ICustomEvent[]>([]);
  const [current_custom_fri, setCurrentCustomFri] = useState<ICustomEvent[]>([]);
  const [current_custom_sat, setCurrentCustomSat] = useState<ICustomEvent[]>([]);
  const [current_custom_sun, setCurrentCustomSun] = useState<ICustomEvent[]>([]);

  useEffect(() => {
    setCurrentOrderSun(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 7));
    setCurrentOrderMon(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 1));
    setCurrentOrderTue(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 2));
    setCurrentOrderWed(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 3));
    setCurrentOrderThu(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 4));
    setCurrentOrderFri(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 5));
    setCurrentOrderSat(order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 6));
  }, [order_events, filter]);

  useEffect(() => {
    setCurrentSOSun(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 7));
    setCurrentSOMon(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 1));
    setCurrentSOTue(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 2));
    setCurrentSOWed(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 3));
    setCurrentSOThu(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 4));
    setCurrentSOFri(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 5));
    setCurrentSOSat(self_order_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 6));
  }, [self_order_events, filter]);

  useEffect(() => {
    setCurrentCustomSun(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 7));
    setCurrentCustomMon(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 1));
    setCurrentCustomTue(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 2));
    setCurrentCustomWed(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 3));
    setCurrentCustomThu(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 4));
    setCurrentCustomFri(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 5));
    setCurrentCustomSat(custom_events.filter(event => filterEventType(event, filter)).filter(e => DateTime.fromISO(e?.time ?? "").toLocal().weekday === 6));
  }, [custom_events, filter]);


  return (
    <Fragment>
      <Grid item xs={1.71} sx={[grid_body_style, { borderLeft: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", "flexDirection": "column", gap: "10px", borderLeft: `1px solid ${light_grey_color}` }}>
          {current_order_sun.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />
  	  )}
          {current_so_sun.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_sun.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
        </div>
      </Grid>
      <Grid item xs={1.71} sx={grid_body_style}>
        <div style={{ display: "flex", "flexDirection": "column", gap: "10px" }}>
          {current_order_mon.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />)}
          {current_so_mon.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_mon.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
        </div>
      </Grid>
      <Grid item xs={1.71} sx={grid_body_style}>
        <div style={{ display: "flex", "flexDirection": "column", gap: "10px" }}>
         {current_order_tue.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />)}
          {current_so_tue.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_tue.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
	</div>
      </Grid>
      <Grid item xs={1.71} sx={grid_body_style}>
        <div style={{ display: "flex", "flexDirection": "column", gap: "10px" }}>
         {current_order_wed.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />)}
          {current_so_wed.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_wed.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
	</div>
      </Grid>
      <Grid item xs={1.71} sx={grid_body_style}>
        <div style={{ display: "flex", "flexDirection": "column", gap: "10px" }}>
         {current_order_thu.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />)}
          {current_so_thu.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_thu.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
	</div>
      </Grid>
      <Grid item xs={1.71} sx={grid_body_style}>
       <div style={{ display: "flex", "flexDirection": "column", gap: "10px" }}>
         {current_order_fri.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />)}
          {current_so_fri.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_fri.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
	</div>
      </Grid>
      <Grid item xs={1.71} sx={grid_body_style}>
        <div style={{ display: "flex", "flexDirection": "column", gap: "10px" }}>
         {current_order_sat.map((e, i) => <WeekEvent 
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${market_url}/${e?.order}`} />)}
          {current_so_sat.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${shop_url}/${e?.self_order}`} />)}
          {current_custom_sat.map((e, i) => <WeekEvent
		key={i}
		title={e?.title ?? ""}
		time={e?.time ?? ""}
		color={e?.color ?? "blue"}
		url={`/${event_url}/${detail_url}/${e?.id}`} />)}
	</div>
      </Grid>
    </Fragment>
  )
}
