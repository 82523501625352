/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLabel } from "api/actions/sketch/label";
import { updateLocalLabel } from "api/actions/sketch/label";
/// TYPES ///
import { Label } from "api/types/sketch";
/// COMPONENTS ///
import LabelDialog from "components/quote/dialog/label";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  selected_label: Label
}

export default function UpdateStoreLabel({ open, setOpen, selected_label }: Props) {
  const dispatch = useAppDispatch();
  const [text, setText] = useState<string>("");
  const [size, setSize] = useState<number>(14);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setText(selected_label?.text ?? "");
      setSize(selected_label?.size ?? 14);
      setError(false);
    }
  }, [open]);

  const save = () => {
    if (text === "") {
      setError(true);
      return;
    }

    dispatch(updateLabel({ ...selected_label, text: text, size: size }, selected_label.id));
    dispatch(updateLocalLabel({ ...selected_label, text: text, size: size }));
    close();
  }

  const close = () => {
    setOpen(false);
    setText("");
    setSize(14);
    setError(false);
  }

  return (
    <LabelDialog
      open={open}
      title="Edit Label"
      error={error}
      text={text}
      setText={setText}
      size={size}
      setSize={setSize}
      close={close}
      save={save}
    />
  )
}
