/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopLabel } from "api/types/shop_quote/label";
import { IShopQuote } from "api/types/shop_quote/quote";
import { IShopQuoteFilter } from "api/types/shop_quote/filter";
import { IShopQuoteLineItem } from "api/types/shop_quote/quote_line_item";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";

interface State {
  quote: IShopQuote,
  quotes: IShopQuote[],
  filter: IShopQuoteFilter,
  areas: IShopArea[],
  cut_shapes: IShopCutShape[],
  counters: IShopCounter[],
  labels: IShopLabel[],
  selected_corner: IShopCorner | null,
  selected_cut_shape: IShopCutShape | null,
  counters_loaded: boolean,
  shapes_loaded: boolean,
  quote_loaded: boolean,
  areas_loaded: boolean,
  open_message: boolean,
  updating: boolean,
  quote_created: boolean,
  selected_line_item: IShopQuoteLineItem | null,
  edit_line_item_open: boolean,
  error: string | null
}

const initialState = {
  quote: {},
  quotes: [],
  filter: {},
  areas: [],
  cut_shapes: [],
  counters: [],
  labels: [],
  selected_corner: null,
  selected_cut_shape: null,
  quote_created: false,
  counters_loaded: false,
  shapes_loaded: false,
  open_message: false,
  quote_loaded: false,
  areas_loaded: false,
  updating: false,
  selected_line_item: null,
  edit_line_item_open: false,
  error: null
}

export const shopQuoteReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CREATE_SHOP_QUOTE:
      return {
        ...state,
        quotes: [action.payload, ...state.quotes],
        quote: action.payload,
        updating: false,
        quote_created: true,
        error: ""
      }
    case ActionType.CLEAR_SHOP_QUOTE_CREATED:
      return {
        ...state,
        quote_created: false
      }
    case ActionType.GET_SHOP_QUOTE:
      return {
        ...state,
        quote: action.payload,
        quote_loaded: true,
        error: ""
      }
    case ActionType.GET_SHOP_QUOTES:
      return {
        ...state,
        quotes: action.payload,
        quote_loaded: true,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_SHOP_QUOTE:
      return {
        ...state,
        quote: action.payload
      }
    case ActionType.UPDATE_SHOP_QUOTE:
      return {
        ...state,
        updating: false,
        quote: action.payload,
        error: ""
      }
    case ActionType.DELETE_SHOP_QUOTE:
      return {
        ...state,
        quotes: [...state.quotes.filter(item => item.id !== action.payload)],
        updating: false,
        error: ""
      }
    case ActionType.SET_CUSTOMER_ON_SHOP_QUOTE:
      return {
        ...state,
        quote: { ...state.quote, customer_data: action.customer, customer: action.customer.id }
      }
    case ActionType.SET_JOB_ON_SHOP_QUOTE:
      return {
        ...state,
        quote: {
          ...state.quote,
          job: action.job ? action.job.id : null,
          job_data: action.job
        }
      }
    case ActionType.CLEAR_SHOP_QUOTES:
      return {
        ...state,
        quotes: []
      }
    case ActionType.CLEAR_SHOP_QUOTE:
      return {
        ...state,
        quote: {},
        areas: [],
        counters: [],
        cut_shapes: [],
        labels: [],
        quote_loaded: false,
        areas_loaded: false,
        counters_loaded: false,
        shapes_loaded: false,
        selected_corner: null,
        selected_cut_shape: null,
        updating: false,
        error: ""
      }
    case ActionType.MARK_SHOP_QUOTE_CHANGE:
      return {
        ...state,
        quote: { ...state.quote, changed: true }
      }
    case ActionType.SET_SHOP_QUOTE_FILTER:
      return {
        ...state,
        filter: action.filter
      }
    case ActionType.GET_SHOP_AREAS:
      return {
        ...state,
        areas: action.payload,
        areas_loaded: true
      }
    case ActionType.CREATE_SHOP_AREA:
      return {
        ...state,
        areas: [...state.areas, action.payload],
        updating: false
      }
    case ActionType.CREATE_LOCAL_SHOP_AREA:
      return {
        ...state,
        areas: [...state.areas, action.payload],
      }
    case ActionType.UPDATE_LOCAL_SHOP_AREA:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.uuid ? { ...area, ...action.payload } : area)],
      }
    case ActionType.DELETE_LOCAL_SHOP_AREA:
      return {
        ...state,
        areas: [...state.areas.filter(area => area.uuid !== action.payload)],
      }
    case ActionType.CREATE_LOCAL_SHOP_SLAB:
      return {
        ...state,
        quote: { ...state.quote, changed: true },
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, slabs: [...area.slabs, action.payload] }
          : area)]
      }
    case ActionType.DELETE_LOCAL_SHOP_SLAB:
      return {
        ...state,
        quote: { ...state.quote, changed: true },
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, slabs: [...area.slabs.filter(slab => slab.uuid !== action.payload.uuid)] } : area)]
      }
    case ActionType.CREATE_LOCAL_SHOP_COLOR_OPTION:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, color_options: [...area.color_options, action.payload] } :
          area
        )]
      }
    case ActionType.UPDATE_LOCAL_SHOP_COLOR_OPTION:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          {
            ...area, color_options: [...area.color_options.map(color_option => color_option.uuid === action.payload.uuid ?
              action.payload :
              color_option
            )]
          } :
          area
        )]
      }
    case ActionType.DELETE_LOCAL_SHOP_COLOR_OPTION:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, color_options: [...area.color_options.filter(color_option => color_option.uuid !== action.payload.uuid)] } :
          area
        )]
      }
    case ActionType.CREATE_SHOP_ADDON:
      return {
        ...state,
        quote: {
          ...state.quote,
          addons: [...state.quote.addons, action.addon]
        }
      }
    case ActionType.UPDATE_SHOP_ADDON:
      return {
        ...state,
        quote: {
          ...state.quote,
          addons: [...state.quote.addons.map(adn => adn.id === action.addon.id ? action.addon : adn)]
        }
      }
    case ActionType.DELETE_SHOP_ADDON:
      return {
        ...state,
        quote: {
          ...state.quote,
          addons: [...state.quote.addons.filter(adn => adn.id !== action.addon.id)]
        }
      }
    case ActionType.UPDATE_SHOP_QUOTE_LINE_ITEM:
      return {
        ...state,
        areas: [...state.areas.map(area => area.id === action.payload.area ?
          {
            ...area, line_items: area.line_items.map(item => item.id === action.payload.id ?
              action.payload : item)
          } :
          area
        )]
      }
    case ActionType.SET_SELECTED_SHOP_LINE_ITEM:
      return {
        ...state,
        selected_line_item: action.payload
      }
    case ActionType.GET_SHOP_COUNTERS:
      return {
        ...state,
        counters: action.payload,
        counters_loaded: true
      }
    case ActionType.GET_SHOP_CUT_SHAPES:
      return {
        ...state,
        cut_shapes: action.payload,
        shapes_loaded: true
      }
    case ActionType.CREATE_LOCAL_SHOP_COUNTER:
      return {
        ...state,
        counters: [...state.counters, action.payload]
      }
    case ActionType.UPDATE_LOCAL_SHOP_COUNTER:
      return {
        ...state,
        counters: [...state.counters.filter(counter => counter.uuid !== action.payload.uuid), action.payload]
      }
    case ActionType.DELETE_LOCAL_SHOP_COUNTER:
      return {
        ...state,
        counters: [...state.counters.filter(counter => counter.uuid !== action.payload)]
      }
    case ActionType.CREATE_SHOP_LABEL:
      return {
        ...state,
        labels: [...state.labels, action.label],
        updating: false
      }
    case ActionType.UPDATE_SHOP_LABEL:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_SHOP_LABEL:
      return {
        ...state,
        labels: [...state.labels.filter(label => label.id !== action.label.id), action.label]
      }
    case ActionType.DELETE_SHOP_LABEL:
      return {
        ...state,
        labels: [...state.labels.filter(label => label.id !== action.id)],
        updating: false
      }
    case ActionType.GET_SHOP_LABELS:
      return {
        ...state,
        labels: action.labels
      }
    case ActionType.CREATE_LOCAL_SHOP_COOKTOP:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                cooktops: [...counter.cooktops, action.payload]
              })
        ]
      }
    case ActionType.UPDATE_LOCAL_SHOP_COOKTOP:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                cooktops: [...counter.cooktops.filter(cooktop => cooktop.uuid !== action.payload.uuid), action.payload]
              })
        ],
      }
    case ActionType.DELETE_LOCAL_SHOP_COOKTOP:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                cooktops: [...counter.cooktops.filter(cooktop => cooktop.uuid !== action.payload.uuid)]
              })
        ]
      }

    case ActionType.CREATE_LOCAL_SHOP_SINK:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.sink.counter_uuid ?
              counter :
              {
                ...counter,
                sinks: [...counter.sinks, action.sink]
              })
        ]
      }
    case ActionType.UPDATE_LOCAL_SHOP_SINK:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                sinks: [...counter.sinks.filter(sink => sink.uuid !== action.payload.uuid), action.payload]
              })
        ]
      }
    case ActionType.DELETE_LOCAL_SHOP_SINK:
      return {
        ...state,
        counters: [
          ...state.counters.map(counter =>
            counter.uuid !== action.payload.counter_uuid ?
              counter :
              {
                ...counter,
                sinks: [...counter.sinks.filter(sink => sink.uuid !== action.payload.uuid)]
              })
        ]
      }
    case ActionType.SET_SELECTED_SHOP_CUT_SHAPE:
      return {
        ...state,
        selected_cut_shape: action.payload
      }
    case ActionType.DELETE_SHOP_CUT_SHAPE:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.filter(cut_shape => cut_shape.uuid !== action.payload.uuid)]
      }
    case ActionType.DELETE_LOCAL_SHOP_CUT_SHAPE:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.filter(cut_shape => cut_shape.counter_uuid !== action.payload)]
      }
    case ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPE:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.filter(cut_shape => cut_shape.uuid !== action.payload.uuid), action.payload]
      }
    case ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPES:
      return {
        ...state,
        cut_shapes: [
          ...state.cut_shapes.filter(cut_shape => cut_shape.counter_uuid !== action.counter_uuid), ...action.payload
        ]
      }
    case ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPES_BY_AREA:
      return {
        ...state,
        cut_shapes: [
          ...state.cut_shapes.filter(cut_shape => cut_shape.area_uuid !== action.area_uuid), ...action.payload
        ]
      }
    case ActionType.CREATE_LOCAL_SHOP_CUT_BUMP:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.map(cut_shape => cut_shape.counter_uuid === action.bump.counter_uuid ?
          {
            ...cut_shape, cut_corners: [
              ...cut_shape.cut_corners.map(cut_corner => cut_corner.corner_uuid === action.bump.corner_uuid ?
                {
                  ...cut_corner, cut_bumps:
                    [...cut_corner.cut_bumps, action.payload]
                } :
                cut_corner
              )
            ]
          } :
          cut_shape
        )]
      }
    case ActionType.UPDATE_LOCAL_SHOP_CUT_BUMP:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.map(cut_shape => cut_shape.counter_uuid === action.bump.counter_uuid ?
          {
            ...cut_shape, cut_corners: [
              ...cut_shape.cut_corners.map(cut_corner => cut_corner.corner_uuid === action.bump.corner_uuid ?
                {
                  ...cut_corner, cut_bumps:
                    [...cut_corner.cut_bumps.map(cut_bump => cut_bump.bump_uuid === action.bump.uuid ?
                      { ...cut_bump, ...action.payload } :
                      cut_bump
                    )]
                } :
                cut_corner
              )
            ]
          } :
          cut_shape
        )]
      }
    case ActionType.DELETE_LOCAL_SHOP_CUT_BUMP:
      return {
        ...state,
        cut_shapes: [...state.cut_shapes.map(cut_shape => cut_shape.counter_uuid === action.payload.counter_uuid ?
          {
            ...cut_shape, cut_corners: [...cut_shape.cut_corners.map(cut_corner => cut_corner.corner_uuid === action.payload.corner_uuid ?
              { ...cut_corner, cut_bumps: [...cut_corner.cut_bumps.filter(cut_bump => cut_bump.bump_uuid !== action.payload.uuid)] } :
              cut_corner
            )]
          } :
          cut_shape
        )]
      }
    case ActionType.CREATE_LOCAL_SHOP_BUMP:
      return {
        ...state,
        counters: [...state.counters.map(counter => counter.uuid === action.payload.counter_uuid ?
          {
            ...counter, corners: [...counter.corners.map(corner => corner.uuid === action.payload.corner_uuid ?
              { ...corner, bumps: [...corner.bumps, action.payload] } :
              corner
            )]
          } :
          counter
        )]
      }
    case ActionType.UPDATE_LOCAL_SHOP_BUMP:
      return {
        ...state,
        counters: [...state.counters.map(counter => counter.uuid === action.counter_uuid ?
          {
            ...counter, corners: [...counter.corners.map(corner => corner.uuid === action.payload.corner_uuid ?
              {
                ...corner, bumps: [...corner.bumps.map(bump => bump.uuid === action.payload.uuid ?
                  action.payload :
                  bump
                )]
              } :
              corner
            )]
          } :
          counter
        )]
      }
    case ActionType.DELETE_LOCAL_SHOP_BUMP:
      return {
        ...state,
        counters: [...state.counters.map(counter => counter.uuid === action.payload.counter_uuid ?
          {
            ...counter, corners: [...counter.corners.map(corner => corner.uuid === action.payload.corner_uuid ?
              { ...corner, bumps: [...corner.bumps.filter(bump => bump.uuid !== action.payload.uuid)] } :
              corner
            )]
          } :
          counter
        )]
      }
    case ActionType.SET_FABRICATION_JOB_ON_SHOP_QUOTE:
      return {
        ...state,
        quote: {
          ...state.quote,
          job: action.job ? action.job.id : null,
          job_data: action.job
        }
      }
    case ActionType.SHOP_QUOTE_ACTION_SUCCESS:
      return {
        ...state,
        quote: { ...state.quote, changed: true },
        updating: false,
        error: ""
      }
    case ActionType.SHOP_QUOTE_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.SHOP_QUOTE_ERROR:
      return {
        ...state,
        updating: false,
        error: action.payload
      }
    default:
      return state;
  }
}
