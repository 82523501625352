/// REACT ///
import { Fragment } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router";
/// MUI ///
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import BookEvent from "components/events/dialog/booking/self_order";
/// FM ///
import { motion } from "framer-motion";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// DATE TIME ///
import { DateTime } from "luxon";
/// VALUES ///
import { getEventType } from "values/event";
/// STYLES ///
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

interface Props {
  id: number,
  event_type: string,
  time: string,
  notify_email: string,
  description: string,
  color: string,
  completed: boolean,
  booking: boolean,
  booked: boolean,
  selected_id: number | string,
  setEvent: () => void,
  completeEvent: (complete: boolean) => void
}

export default function ProgressColumn({
  id,
  time,
  notify_email,
  event_type,
  description,
  color,
  completed,
  booking,
  booked,
  selected_id,
  setEvent,
  completeEvent
}: Props) {
  const navigate = useNavigate();
  const [open_booking, setOpenBooking] = useState<boolean>(false);

  return (
    <div style={{ width: "100%", position: "relative", padding: "5px", paddingTop: "10px", backgroundColor: selected_id !== id ? "rgba(255, 255, 255, 0.0)" : "#fafafa" }}>
      <motion.button
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
	        backgroundColor: selected_id !== id ? "rgba(255, 255, 255, 0.0)" : "#fafafa",
          alignItems: "center",
          overflow: "visible",
          border: "none",
	        flexDirection: "row",
        }}
        onClick={setEvent}>
        <motion.div style={{ width: "50%" }}>
          <Typography variant="body2" align="left">
            {DateTime.fromISO(time).toLocal().toFormat("LLL dd - h:mm a")}
          </Typography>
          <Typography variant="body2" align="left" sx={{ marginTop: "5px" }}>
            <b>{getEventType(event_type)}</b>
          </Typography>
    {
	    selected_id === id ?
      <motion.div key={selected_id === id ? "show" : "hide"  } style={{paddingLeft: "8px", paddingTop: "10px", paddingBottom: "5px"}}>
        <Grid container>
        {
          notify_email !== "" ?
            <Grid item xs={12} md={8}>
              <Typography variant="body2" align="left">
                <u>Assigned To</u> <br/> {notify_email}
              </Typography>
            </Grid> :
          null
        }
        {
          description !== "" ?
          <Fragment>
                  <Grid item xs={12}>
              <Typography variant="body2" align="left">
                <u>Notes</u> <br /> {description}
              </Typography>
            </Grid>
                </Fragment> :
          null
        }
        </Grid>
      </motion.div> :
	    null
      }
      {
        selected_id === id ?
        <motion.div
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          style={{ width: "100%", height: "5px", backgroundColor: color, marginTop: "5px" }}>
        </motion.div> :
        <div style={{ width: "100%", height: "5px", backgroundColor: "#fff", marginTop: "5px" }}></div>
      }
      </motion.div>
      {
	      selected_id === id ?
	      <div style={{display: "flex", flexDirection: "row", alignItems: "end"}}>
        	<BaseButton clickEvent={() => navigate(`/${event_url}/${update_url}/${shop_url}/${id}`)} text="Edit" icon={faEdit} />
          {
            completed ?
              <BaseButton clickEvent={() => completeEvent(false)} text="Mark Incomplete" error={true} icon={faXmark} /> :
              booking && !booked ?
                <BaseButton clickEvent={() => setOpenBooking(true)} text="Book" icon={faBook} /> :
                <BaseButton clickEvent={() => completeEvent(true)} text="Mark Complete" icon={faCheck} />
          }
        </div> :
	      <Icon sx={{backgroundColor: color, borderRadius: "50%", color: "#fff", width: "25px", height: "25px"}}>
          <FontAwesomeIcon icon={booking && !booked ? faBook : completed ? faCheck : faXmark} size="xs" />
      	</Icon>
      }
      </motion.button>
      <BookEvent open={open_booking} setOpen={setOpenBooking} id={id} />
    </div>
  )
}
