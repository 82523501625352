/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { IFabricationJobError } from "api/types/customer";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";

interface Props {
  title: string,
  email: string,
  setEmail: (email: string) => void,
  entry_notes: string,
  setEntryNotes: (entry_notes: string) => void,
  geo_address: string,
  setGeoAddress: (geo_address: string, lat: number, lng: number, address: string, city: string, province: string) => void,
  phone_number: string,
  setPhoneNumber: (phone_number: string) => void,
  errors: IFabricationJobError,
  setErrors: (errors: IFabricationJobError) => void,
  update: () => void
}

export default function UpdateJob({
  title,
  email,
  setEmail,
  entry_notes,
  setEntryNotes,
  geo_address,
  setGeoAddress,
  phone_number,
  setPhoneNumber,
  errors,
  setErrors,
  update
}: Props) {
  const navigate = useNavigate();


  const updateGeo = (label: string, lat: number, lng: number, add: string, cit: string, prov: string) => {
    setGeoAddress(
      label,
      lat,
      lng,
      add,
      cit,
      prov
    );
    setErrors({ ...errors, geo_error: "", geo_has_error: false });
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title={title} size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(-1)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={update} text="Save" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={errors?.email_has_error}
              helperText={errors?.email_error ?? ""}
              label="Email" />
            <TextField
              value={phone_number}
              onChange={e => setPhoneNumber(e.target.value)}
              label="Phone Number" />
            <AddressAutocomplete
              input_label="Site Location"
              label={geo_address}
              error={errors?.geo_error ?? ""}
              has_error={errors?.geo_has_error}
              geoError={geoError}
              updateGeo={updateGeo} />
            <TextField
              value={entry_notes}
              onChange={e => setEntryNotes(e.target.value)}
              label="Notes" />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
