/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
/// TYPES ///
import { QuoteErrors } from "api/types/quote";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";

interface Props {
  base_url: string,
  name: string,
  setName: (name: string) => void,
  estimate_number: string,
  setEstimateNumber: (estimate_number: string) => void,
  errors: QuoteErrors,
  save: () => void
}

export default function UpdateQuote({
  base_url,
  name,
  setName,
  estimate_number,
  setEstimateNumber,
  errors,
  save
}: Props) {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Edit Quote" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={save} text="Save" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              value={name}
              onChange={e => setName(e.target.value)}
              error={errors.name_has_error}
              helperText={errors.name_error}
              label="Quote Name"
              size="small" />
            <TextField
              value={estimate_number}
              onChange={e => setEstimateNumber(e.target.value)}
              label="Quote Number"
              size="small" />
          </Box>
        </Grid>
      </Grid>
    </motion.div >
  )
}
