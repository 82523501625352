/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
/// DATE ///
import { DateTime } from "luxon";

interface Props {
   color: string,
   title: string,
   time: string,
   url: string
}

export default function WeekEvent({ color, title, time, url }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <motion.button
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.03 }}
      style={{
        backgroundColor: color,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "5px",
        paddingTop: is_md ? "10px" : "4px",
        paddingBottom: is_md ? "10px" : "4px",
        paddingLeft: is_md ? "15px" : "4px",
        paddingRight: is_md ? "15px" : "4px",
        marginRight: is_md ? "5px" : "2px",
        marginLeft: is_md ? "5px" : "2px",
        cursor: "pointer",
        border: "none",
        overflow: "hidden"
      }}
      onClick={() => navigate(url)}>
      <div style={{ flexDirection: "column", display: "flex", overflow: "hidden" }}>
        <Typography variant="body1" sx={{ color: "#fff", whiteSpace: "normal" }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: "25px", color: "#fff" }}>
          {DateTime.fromISO(time).toLocal().toFormat("h:mm a")}
        </Typography>
      </div>
    </motion.button>
  )
}
