/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { updateProfile } from "api/actions/authentication";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { User } from "api/types/authentication";
/// MUI ///
import { Box } from "@mui/material";
import { Fab } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
import AddressAutocomplete from "components/universal/autocomplete/geo";
import Map from "components/universal/map/map";
import ProvinceSelector from "components/universal/select_field/province";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import AddIcon from "@mui/icons-material/Add";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import RemoveIcon from "@mui/icons-material/Remove";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { stores_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { stores_list } from "values/breadcrumbs/store";
import { store } from "values/breadcrumbs/store";
import { store_update } from "values/breadcrumbs/store";
/// COLOR ///
import { grey_color } from "styles/style";

export default function UpdateStore() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authentication);
  const [error, setError] = useState<string>("");
  const [has_error, setHasError] = useState<boolean>(false);
  const [geo, setGeo] = useState<User>({});
  const [changed, setChanged] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(8);

  useEffect(() => {
    setGeo({province: user?.province ?? ""});
  }, [setGeo, user]);

  useEffect(() => {
    dispatch(setBreadcrumbs([stores_list, store, store_update]));
  }, [dispatch]);

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setGeo({
      geo_address: label,
      lat: lat,
      lng: lng
    });
    setError("");
    setHasError(false);
    setChanged(true);
  }

  const geoError = () => {
    setError("Geo location not found for this address");
    setHasError(true);
  }

  const update = () => {
    if (changed && (geo.lat || geo.lng || geo.geo_address)) {
      if (isNaN(geo.lat) || isNaN(geo.lng)) {
        geoError();
        return;
      }
    }
    dispatch(updateProfile(geo, user.id));
    navigate(`/${stores_url}/${detail_url}/${user.id}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Edit Store" size_lg={6} endProps={
          <Box>
            <BaseButton text="Cancel" icon={faTimes} clickEvent={() => navigate(`/${stores_url}/${detail_url}/${user.id}`)} />
            <BaseButton clickEvent={update} text="Save" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              defaultValue={user?.markup_rate ?? 0}
              type="number"
              onChange={e => setGeo({ ...geo, markup_rate: Number(e.target.value) })}
              label="Markup Rate(%)"
              size="small" />
            <TextField
              fullWidth
              defaultValue={user?.payment_terms ?? ""}
              onChange={e => setGeo({ ...geo, payment_terms: e.target.value })}
              size="small"
              label="Payment Terms"
              multiline />
              <Typography variant="body2" sx={{fontSize: "0.85rem", paddingLeft: "5px", paddingTop: "10px", color: grey_color }}>
                Invoice Billing
              </Typography>
              <TextField
                defaultValue={user?.address ?? ""}
                onChange={e => setGeo({...geo, address: e.target.value})}
                label="Address" />
              <TextField
                defaultValue={user?.city ?? ""}
                onChange={e => setGeo({...geo, city: e.target.value})}
                label="City" />
              <ProvinceSelector
                province={geo?.province ?? ""}
                setProvince={province => setGeo({...geo, province: province })} />
              <TextField
                defaultValue={user?.postal_code ?? ""}
                onChange={e => setGeo({...geo, postal_code: e.target.value})}
                label="Postal Code" />
            <Typography variant="body2" sx={{fontSize: "0.85rem", paddingLeft: "5px", paddingTop: "10px", color: grey_color }}>
              Invoice Billing
            </Typography>
            <AddressAutocomplete
              input_label="Store Address"
              label={geo.geo_address ? geo.geo_address : user.geo_address}
              error={error}
              has_error={has_error}
              geoError={geoError}
              updateGeo={updateGeo} />
          </Box>
          <Box sx={{ marginTop: "30px" }}>
            <Map
              lat={geo.lat ? geo.lat : user.lat}
              lng={geo.lng ? geo.lng : user.lng}
              radius={0}
              isStatic={true}
              zoom={zoom} />
            <Box sx={{ position: "relative", bottom: 50, right: 5 }}>
              <Box display="flex" justifyContent="right">
                <Fab sx={{ marginRight: 1 }} size="small" color="primary" aria-label="zoom-out" onClick={() => setZoom(prev => prev - 1)}>
                  <RemoveIcon />
                </Fab>
                <Fab size="small" color="primary" aria-label="zoom-in" onClick={() => setZoom(prev => prev + 1)}>
                  <AddIcon />
                </Fab>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
