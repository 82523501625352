/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
//// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
import { updateProfile } from "api/actions/authentication";
/// TYPES ///
import { User } from "api/types/authentication";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { TextField } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { profile_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { profile as prof_breadcrumb } from "values/breadcrumbs/profile";
import { update_profile } from "values/breadcrumbs/profile";

export default function UpdateProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);
  const [profile, setProfile] = useState<User>({});

  useEffect(() => {
    dispatch(setBreadcrumbs([prof_breadcrumb, update_profile]));
  }, [dispatch]);

  const save = () => {
    dispatch(updateProfile(profile, user.id));
    navigate(`/${profile_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Edit Profile" endProps={
            <Box>
              <BaseButton text="Cancel" icon={faXmark} clickEvent={() => navigate(`/${profile_url}`)} />
              <BaseButton text="Save" icon={faSave} clickEvent={save} />
            </Box>
          } />
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Stack direction="row" spacing={1}>
            <TextField
              defaultValue={user.first_name}
              onChange={e => setProfile({ ...profile, first_name: e.target.value })}
              label="First Name" />
            <TextField
              defaultValue={user.last_name}
              onChange={e => setProfile({ ...profile, last_name: e.target.value })}
              label="Last Name" />
          </Stack>
        </Grid>
      </Grid>
    </motion.div>
  )
}
