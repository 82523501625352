/// TYPES ///
import { Area } from "api/types/quote";
import { ColorOption } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopColorOption } from "api/types/shop_quote/color_option";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";


interface Props {
  area: IShopArea | Area,
  option: IShopColorOption | ColorOption
}

export default function ColorOptsRow({ area, option }: Props) {
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Fragment>
      <Grid item xs={6} md={3}>
        <Typography variant="body2" sx={item_style(true, is_md ? "20px" : "50px")}>
          {area?.name ?? ""}
          {!is_md ? `\n${option?.material_name ?? ""} ${option?.color_name ?? ""} ${option?.material_thickness ?? ""}` : ""}
        </Typography>
      </Grid>
      <Grid item xs={0} md={6}>
        <Typography variant="body2" sx={item_style(true, is_md ? "20px" : "50px")}>
          {is_md ? `${option?.material_name ?? ""} ${option?.color_name ?? ""} ${option?.material_thickness ?? ""}` : ""}
        </Typography>
      </Grid>
      <Grid item xs={5.5} md={3}>
        <Typography variant="body2" align="right" sx={item_style(true, is_md ? "20px" : "50px")}>
          ${option?.subtotal?.toFixed(2) ?? "0.00"}
        </Typography>
      </Grid>
    </Fragment>
  )
}
