/// MUIX ///
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridEditRowProps } from "@mui/x-data-grid";
import { GridRowId } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid";
import { gridEditRowsStateSelector } from "@mui/x-data-grid";
/// ICONS ///
import SaveIcon from "@mui/icons-material/Save";
/// VALUE CHECKS ///
import { JSONIsEmpty } from "functions/value";

interface Props {
  id: GridRowId,
  handleSaveClick: (id: GridRowId, edit_state: GridEditRowProps) => void
}

export default function GridSaveButton({ id, handleSaveClick }: Props) {
  const apiRef = useGridApiContext()
  const editState = gridEditRowsStateSelector(apiRef.current.state);

  const save = () => {
    if(JSONIsEmpty(editState[id])){
      return;
                                }
    handleSaveClick(id, editState[id]);
  }

  return (
    <GridActionsCellItem
      icon={<SaveIcon />}
      label="Save"
      sx={{
        color: "primary.main",
      }}
      onClick={save}
    />
  )
}

