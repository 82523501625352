/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getEvent } from "api/actions/event";
import { updateCustomEvent } from "api/actions/event";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import BookEvent from "components/events/dialog/booking/self_order";
import CalendarSidebar from "../side_bar";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// ICONS ///
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// LUXON ///
import { DateTime } from "luxon";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
import { mobile_calendar_content_style } from "styles/universal/page";
import { item_style } from "styles/quote_preview/section";
import { item_align } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

export default function CustomEvent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));
  const { id } = useParams();
  const { custom_event, updating } = useAppSelector((state) => state.event);
  const [open_booking, setOpenBooking] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(getEvent(id));
    }
  }, [dispatch, id]);

  const completeEvent = (complete: boolean) => {
    dispatch(updateCustomEvent({ completed: complete }, custom_event?.id));
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={is_md ? calendar_content_style : mobile_calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <LoadingPage loading={!custom_event?.id}>
          <Grid container alignItems="flex-start" spacing={3} sx={{ paddingTop: "25px", paddingRight: is_md ? "75px" : "0px" }}>
            <PageTitle title={custom_event?.title ?? "Event"} endProps={
              <Box>
              <BaseButton clickEvent={() => navigate(`/${event_url}/${update_url}/${detail_url}/${id}`)} text="Edit" icon={faEdit} />
              {
                custom_event?.completed ?
                  <BaseButton clickEvent={() => completeEvent(false)} text="Mark Incomplete" error={true} icon={faXmark} /> :
                  custom_event?.booking && !custom_event?.booked ?
                    <BaseButton clickEvent={() => setOpenBooking(true)} text="Book" icon={faBook} /> :
                    <BaseButton clickEvent={() => completeEvent(true)} text="Mark Complete" icon={faCheck} />
              }
              </Box>
            }/>
            <Grid item xs={12}>
              <Box sx={header_style}></Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" sx={item_style()}>
                <b>Date</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body2" sx={[item_style(), item_align]}>
                {DateTime.fromISO(custom_event?.time).toLocal().toFormat("LLL dd h:mm a")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" sx={item_style()}>
                <b>Type</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body2" sx={[item_style(), item_align]}>
                {custom_event?.event_type}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" sx={item_style()}>
                <b>Notes</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body2" sx={[item_style(), item_align]}>
                {custom_event?.description}
              </Typography>
            </Grid>
          </Grid>
        </LoadingPage>
      </div>
      <CalendarSidebar />
      <BookEvent open={open_booking} setOpen={setOpenBooking} id={custom_event?.id ?? 0} />
      <Loading loading={updating} />
    </motion.div >
  )
}
/*  color = CharField(max_length=9, default="#0063fe")
    completed = BooleanField(default=False)
    completed_time = DateField(default=date.today)
    start_time = DateTimeField(default=now)
    end_time = DateTimeField(default=now)
    date_confirmed = BooleanField(default=False)

*/
