/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { getPriceLists } from "api/actions/price_list";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import MarketPlHeader from "./header";
import MarketPlItem from "./row";
import PageTitle from "components/universal/page/title";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { mat_tile_style } from "styles/material";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/quote_preview/section";
/// ANIMATION ///
import { mat_tile_anim } from "styles/material";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { universal_price_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";


export default function PriceLists() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { price_lists } = useAppSelector(state => state.price_list);

  useEffect(() => {
    dispatch(getPriceLists());
    dispatch(setBreadcrumbs([list]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title="Prices" endProps={<div></div>} />
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12} sx={row_header_style}>
            <Typography variant="h6" sx={{ ...title_style, paddingTop: "1px" }}>
              {price_lists.length} results
            </Typography>
          </Grid>
          <MarketPlHeader />
          <Box display="flex" flexDirection="row" gap="20px" justifyContent="space-evenly" flexWrap="wrap" marginTop="10px" width="100%" paddingTop="25px">

            {
              price_lists?.map(price_list => <MarketPlItem item={price_list} />)
            }
            <motion.button
              initial={{ opacity: 0, right: 20 }}
              animate={{ opacity: 1, right: 0, transition: { delay: 0.2 } }}
              style={mat_tile_style}
              whileHover={mat_tile_anim}
              onClick={() => navigate(`s/${universal_price_url}`)}>
              <Typography variant="h6" sx={{ marginTop: "15px" }}>
                Rates
              </Typography>
              <FontAwesomeIcon icon={faHandHoldingDollar} size="2xl" />
            </motion.button>
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}

