/// REACT ///
import { Fragment } from "react";
/// MUI ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// STYLES ///
import { row_header_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";

export default function ColorOptsHeader() {
  const theme = useTheme();
  const is_md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Fragment>
      <Grid item xs={6} md={3} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
          <b>Area</b>
        </Typography>
      </Grid>
      <Grid item xs={0} md={6} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
        <b>
        {
          is_md ?
        "Color" :
          ""
        }
        </b>
        </Typography>
      </Grid>
      <Grid item xs={5.5} md={3} sx={row_header_style}>
        <Typography variant="body2" align="right" sx={row_text_item_style}>
          <b>Subtotal</b>
        </Typography>
      </Grid>
    </Fragment>
  )
}

