/// HOOKS ///
import { useNavigate } from "react-router-dom";
import useQuoteTotals from "hooks/quote/totals";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/// FM ///
import { motion } from "framer-motion";
/// TYPES ///
import { Quote } from "api/types/quote";
/// LUXON ///
import { DateTime } from "luxon";
/// STYLES ///
import { row_text_motion } from "styles/universal/row";
import { row_btn_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";
/// URLS ///
import { store_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";

interface Props {
  quote: Quote
}

export default function CxQuoteItem({ quote }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const is_md =  useMediaQuery(theme.breakpoints.up("md"));
  const totals = useQuoteTotals(quote);

  return (
    <Grid item xs={12}>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        variants={row_text_motion}
        onClick={() => navigate(`/${store_url}/${quote_url}/${detail_url}/${quote?.id ?? 0}/${preview_url}`)}
        style={row_btn_style}>
        <Grid container>
          <Grid item xs={6} md={4}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {DateTime.fromISO(quote?.date_created ?? "").toLocal().toFormat(is_md ? "LLL dd yyyy" : "L/d/yy")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body2" noWrap sx={[row_text_item_style, {textAlign: {xs: "right", md: "left" }}]}>
              {quote?.name ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={0} md={4}>
            <Typography variant="body2" align="right" noWrap sx={row_text_item_style}>
              {
                is_md ?
                `${(totals.subtotal + totals.tax1 + totals.tax2 + totals.discount).toFixed(2)}` :
                ""
              }
            </Typography>
          </Grid>
        </Grid>
      </motion.button>
    </Grid>
  )
}
