/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { Fragment } from "react";
import { useState } from "react";
/// ACTIONS ///
import { deleteAddon } from "api/actions/quotes/addon";
/// TYPES ///
import { Area } from "api/types/quote";
import { IAddon } from "api/types/quote";
import { Quote } from "api/types/quote";
/// COMPONENTS ///
import QuoteSummary from "components/quote/tabs/quote_details/summary";
import StoreSaveAddon from "components/store_quote/quote/dialog/addon/save";

interface Props {
  quote: Quote,
  areas: Area[],
  is_fitted: boolean,
  generateQuote: () => void
}

export default function StoreQuoteSummary({ quote, areas, is_fitted, generateQuote }: Props) {
  const dispatch = useAppDispatch();
  const [open_save, setOpenSave] = useState<boolean>(false);
  const [open_delete, setOpenDelete] = useState<boolean>(false);
  const [addon, setAddon] = useState<IAddon>({});

  const deleteItem = () => {
    dispatch(deleteAddon(addon));
    setAddon({});
    setOpenDelete(false);
  }

  const addAddon = () => {
    setAddon({});
    setOpenSave(true);
  }

  const editAddon = (addon: IAddon) => {
    setAddon(addon);
    setOpenSave(true);
  }

  const openDeleteAddon = (addon: IAddon) => {
    setAddon(addon);
    setOpenDelete(true);
  }

  return (
  <Fragment>
      <QuoteSummary
        quote={quote}
        areas={areas}
        is_fitted={is_fitted}
        addon={addon}
        setAddon={setAddon}
        open_save={open_save}
        setOpenSave={setOpenSave}
        open_delete={open_delete}
        setOpenDelete={setOpenDelete}
        addAddon={addAddon}
        editAddon={editAddon}
        openDeleteAddon={openDeleteAddon}
        deleteAddon={deleteItem}
        generateQuote={generateQuote}
        openEdit={() => void 0} />
      <StoreSaveAddon   quote={quote} open={open_save} title="Add Item" setOpen={setOpenSave} item={addon} setItem={setAddon} />
  </Fragment>
  )
}
